define('torii/providers/-private/utils', ['exports'], function (exports) {
  'use strict';

  exports.overrideLoadScript = overrideLoadScript;
  exports.resetLoadScript = resetLoadScript;
  exports.loadScript = loadScript;
  var alternativeLoadScript = false;

  function overrideLoadScript(state) {
    alternativeLoadScript = state;
  }

  function resetLoadScript() {
    alternativeLoadScript = false;
  }

  function loadScript(src) {
    if (alternativeLoadScript) {
      return alternativeLoadScript(src);
    }
    var scriptTag = document.createElement('script');
    var firstScriptTag = document.getElementsByTagName('script')[0];
    scriptTag.src = src;
    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
  }
});