define('ember-changeset-validations/helpers/changeset', ['exports', 'ember', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset/utils/is-object'], function (exports, _ember, _emberChangeset, _emberChangesetValidations, _emberChangesetUtilsIsObject) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.changeset = changeset;

  var helper = _ember['default'].Helper.helper;

  function changeset(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var model = _ref2[0];
    var validationMap = _ref2[1];

    if ((0, _emberChangesetUtilsIsObject['default'])(validationMap)) {
      return new _emberChangeset['default'](model, (0, _emberChangesetValidations['default'])(validationMap), validationMap);
    }

    return new _emberChangeset['default'](model, validationMap);
  }

  exports['default'] = helper(changeset);
});