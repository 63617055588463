define('ember-changeset-validations/index', ['exports', 'ember', 'ember-changeset-validations/utils/wrap', 'ember-changeset-validations/utils/handle-multiple-validations', 'ember-changeset/utils/is-promise'], function (exports, _ember, _emberChangesetValidationsUtilsWrap, _emberChangesetValidationsUtilsHandleMultipleValidations, _emberChangesetUtilsIsPromise) {
  'use strict';

  exports['default'] = lookupValidator;

  var isEmpty = _ember['default'].isEmpty;
  var isArray = _ember['default'].isArray;

  function lookupValidator() {
    var validationMap = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    return function (_ref) {
      var key = _ref.key;
      var newValue = _ref.newValue;
      var oldValue = _ref.oldValue;
      var changes = _ref.changes;

      var validator = validationMap[key];

      if (isEmpty(validator)) {
        return true;
      }

      if (isArray(validator)) {
        return (0, _emberChangesetValidationsUtilsHandleMultipleValidations['default'])(validator, { key: key, newValue: newValue, oldValue: oldValue, changes: changes });
      }

      var validation = validator(key, newValue, oldValue, changes);

      return (0, _emberChangesetUtilsIsPromise['default'])(validation) ? validation.then(_emberChangesetValidationsUtilsWrap['default']) : [validation];
    };
  }
});