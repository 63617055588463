define('ember-changeset-validations/utils/handle-multiple-validations', ['exports', 'ember', 'ember-changeset/utils/is-promise'], function (exports, _ember, _emberChangesetUtilsIsPromise) {
  'use strict';

  exports['default'] = handleMultipleValidations;

  var emberArray = _ember['default'].A;
  var all = _ember['default'].RSVP.all;
  var get = _ember['default'].get;
  var typeOf = _ember['default'].typeOf;

  /**
   * Rejects `true` values from an array of validations. Returns `true` when there
   * are no errors, or the error object if there are errors.
   *
   * @private
   * @param  {Array} validations
   * @return {Boolean|Any}
   */
  function handleValidations() {
    var validations = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

    var rejectedValidations = emberArray(validations).reject(function (validation) {
      return typeOf(validation) === 'boolean' && validation;
    });

    return get(rejectedValidations, 'length') === 0 || rejectedValidations;
  }

  /**
   * Handles an array of validators and returns Promise.all if any value is a
   * Promise.
   *
   * @public
   * @param  {Array} validators Array of validator functions
   * @param  {String} options.key
   * @param  {Any} options.newValue
   * @param  {Any} options.oldValue
   * @param  {Object} options.changes
   * @return {Promise|Boolean|Any}
   */
  function handleMultipleValidations(validators, _ref) {
    var key = _ref.key;
    var newValue = _ref.newValue;
    var oldValue = _ref.oldValue;
    var changes = _ref.changes;

    var validations = emberArray(validators.map(function (validator) {
      return validator(key, newValue, oldValue, changes);
    }));

    if (emberArray(validations).any(_emberChangesetUtilsIsPromise['default'])) {
      return all(validations).then(handleValidations);
    }

    return handleValidations(validations);
  }
});