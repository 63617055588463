define("ember-cli-sentry/utils/parse-regex-errors", ["exports"], function (exports) {
  "use strict";

  exports.parseRegexErrors = parseRegexErrors;

  function parseRegexErrors(errors) {
    if (!errors || errors.constructor !== Array) {
      return [];
    }

    var regex = new RegExp(/^\/(.*)\/([gimuy]*)$/);
    var regexGroups = { pattern: 1, flags: 2 };

    return errors.map(function (error) {
      if (typeof error && regex.test(error)) {
        var match = regex.exec(error);
        return new RegExp(match[regexGroups.pattern], match[regexGroups.flags]);
      }

      return error;
    });
  }
});