define('ember-changeset-validations/utils/get-messages', ['exports', 'ember', 'ember-changeset-validations/utils/messages'], function (exports, _ember, _emberChangesetValidationsUtilsMessages) {
  'use strict';

  exports['default'] = getMessages;

  /* globals requirejs, requireModule */

  var emberArray = _ember['default'].A;
  var isPresent = _ember['default'].isPresent;
  var keys = Object.keys;

  var matchRegex = /validations\/messages$/gi;
  var cachedRef = null;

  /**
   * Find and load messages module on consuming app. Defaults to addon messages.
   * To define a custom message map, create `my-app/app/validations/messages.js`
   * and export an object.
   *
   * @param  {Object} moduleMap
   * @param  {Boolean} useCache Pass `false` to ignore cached key
   * @return {Object}
   */
  function getMessages() {
    var moduleMap = arguments.length <= 0 || arguments[0] === undefined ? requirejs.entries : arguments[0];
    var useCache = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

    if (useCache && isPresent(cachedRef)) {
      return cachedRef;
    }

    var moduleKey = emberArray(keys(moduleMap)).find(function (module) {
      return isPresent(module.match(matchRegex));
    });
    var messagesModule = isPresent(moduleKey) ? requireModule(moduleKey)['default'] : _emberChangesetValidationsUtilsMessages['default'];
    cachedRef = messagesModule;

    return messagesModule;
  }
});