define('ember-changeset-validations/utils/validation-errors', ['exports', 'ember', 'ember-changeset-validations/utils/get-messages'], function (exports, _ember, _emberChangesetValidationsUtilsGetMessages) {
  'use strict';

  exports.formatDescription = formatDescription;
  exports.formatMessage = formatMessage;
  exports['default'] = buildMessage;

  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var _Ember$String = _ember['default'].String;
  var dasherize = _Ember$String.dasherize;
  var capitalize = _Ember$String.capitalize;
  var assert = _ember['default'].assert;
  var typeOf = _ember['default'].typeOf;
  var isNone = _ember['default'].isNone;
  var get = _ember['default'].get;

  var assign = _ember['default'].assign || _ember['default'].merge;
  var regex = /\{(\w+)\}/g;

  function formatDescription() {
    var key = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

    return capitalize(dasherize(key).split(/[_-]/g).join(' '));
  }

  function formatMessage(message) {
    var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    if (isNone(message) || typeof message !== 'string') {
      return 'is invalid';
    }

    return message.replace(regex, function (s, attr) {
      return context[attr];
    });
  }

  function buildMessage(key, type, value) {
    var context = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

    var description = formatDescription(key);
    var messages = (0, _emberChangesetValidationsUtilsGetMessages['default'])();

    if (context.message) {
      var message = context.message;

      if (typeOf(message) === 'function') {
        var builtMessage = message(key, type, value, context);
        assert('Custom message function must return a string', typeOf(builtMessage) === 'string');

        return builtMessage;
      }

      return formatMessage(message, assign({ description: description }, context));
    }

    return formatMessage(get(messages, type), assign({ description: description }, context));
  }
});