define('torii/router-dsl-ext', ['exports', 'torii/compat/get-router-lib'], function (exports, _toriiCompatGetRouterLib) {
  'use strict';

  var proto = Ember.RouterDSL.prototype;

  var currentMap = null;

  proto.authenticatedRoute = function () {
    this.route.apply(this, arguments);
    currentMap.push(arguments[0]);
  };

  Ember.Router.reopen({
    _initRouterJs: function _initRouterJs() {
      currentMap = [];
      this._super.apply(this, arguments);
      var routerLib = (0, _toriiCompatGetRouterLib['default'])(this);
      routerLib.authenticatedRoutes = currentMap;
    }
  });
});