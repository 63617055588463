define('ember-changeset-validations/validators/presence', ['exports', 'ember', 'ember-changeset-validations/utils/validation-errors'], function (exports, _ember, _emberChangesetValidationsUtilsValidationErrors) {
  'use strict';

  exports['default'] = validatePresence;

  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var get = _ember['default'].get;
  var isPresent = _ember['default'].isPresent;
  var isBlank = _ember['default'].isBlank;

  function _isPresent(_x2) {
    var _again = true;

    _function: while (_again) {
      var value = _x2;
      _again = false;

      if (value instanceof _ember['default'].ObjectProxy || value instanceof _ember['default'].ArrayProxy) {
        _x2 = get(value, 'content');
        _again = true;
        continue _function;
      }

      return isPresent(value);
    }
  }

  function _testPresence(key, value, presence) {
    var context = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

    if (presence) {
      return _isPresent(value) || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'present', value, context);
    } else {
      return isBlank(value) || (0, _emberChangesetValidationsUtilsValidationErrors['default'])(key, 'blank', value, context);
    }
  }
  function validatePresence(opts) {
    return function (key, value) {
      if (typeof opts === 'boolean') {
        return _testPresence(key, value, opts);
      }

      return _testPresence(key, value, opts.presence, opts);
    };
  }
});