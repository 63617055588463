define('ember-changeset-validations/utils/wrap', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = wrapInArray;

  var emberArray = _ember['default'].A;
  var isArray = _ember['default'].isArray;

  /**
   * Wraps a value in an Ember.Array.
   *
   * @public
   * @param  {Any} value
   * @return {Ember.Array}
   */
  function wrapInArray(value) {
    if (isArray(value)) {
      return emberArray(value);
    }

    return emberArray([value]);
  }
});