define('torii/services/iframe', ['exports', 'torii/mixins/ui-service-mixin'], function (exports, _toriiMixinsUiServiceMixin) {
  'use strict';

  var Iframe = Ember.Object.extend(Ember.Evented, _toriiMixinsUiServiceMixin['default'], {
    openRemote: function openRemote(url) {
      this.remote = document.createElement('iframe');
      this.remote.src = url;
      this.remote.id = 'torii-iframe';
      var iframeParent = '.torii-iframe-placeholder';
      document.querySelector(iframeParent).appendChild(this.remote);
    },
    closeRemote: function closeRemote() {
      this.remote.remove();
    },
    pollRemote: function pollRemote() {
      if (document.querySelector('#torii-iframe') === null) {
        this.trigger('didClose');
      }
    }
  });

  exports['default'] = Iframe;
});